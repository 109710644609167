export const topics = [
  {
    id: 0,
    name: "Select a topic",
  },
  {
    id: 1,
    name: "General Feedback",
  },
  {
    id: 2,
    name: "Bug or issue",
  },
  {
    id: 3,
    name: "Marketing or media",
  },
  {
    id: 4,
    name: "Complaint",
  },
];
