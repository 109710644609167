import React, { useEffect, useState } from "react";

import {
  InstagramIcon,
  LinkedInIcon,
  SwiftyIcon,
  TwitterIcon,
} from "../../utils/icons";
import Input from "../Input/Input";

import axios from "axios";
import { ToastContainer, toast } from "react-toastify";

import Button from "../Button/Button.component";
import IsMobile from "../../utils/isMobile";

import "./FooterV2.css";
import { urls } from "../../utils/urls";

const FooterV2 = () => {
  const isMobile = IsMobile();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState("");
  // current year
  const currentYear = new Date().getFullYear();

  const submit = () => {
    setIsLoading(true);
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
      const body = { email, source: "swiftyglobal.com" };
      const url = urls.subscription;
      axios.post(url, body).then(() => {
        setIsLoading(false);
        setEmail("");
      });
    } else {
      toastError();
    }
  };

  const toastError = () => {
    toast.error("Invalid email address!", {
      position: "top-right",
      autoClose: 4000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <div className="footer-v2 container">
      <SwiftyIcon className="footer-icon" />
      <div className="row footer-middle">
        <div className="col-12 col-lg-6">
          <div className="footer-items">
            <p>
              This site provides news information, informational guides,
              schedules of sporting events. It is intended for use by
              individuals aged 18 and older.
            </p>
            {/* <p>
              This site is a sports and casino blogging site. We do not claim
              any association with, nor endorsement, nor sponsorship by, any
              official partners.
            </p> */}
          </div>

          <div className="footer-items items d-flex gap-4">
            <a href="/privacy">Privacy policy</a>
            <a href="/termsandconditions">Terms & Conditions</a>
            <a href="/cookiepolicy"> Cookie Policy</a>
          </div>
        </div>
        <div className="col-12 d-flex align-items-end gap-3 col-lg-4">
          <Input
            value={email}
            label="Stay up to date"
            placeholder={"Enter your email"}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            text="Subscribe"
            className={`footer-button ${email ? "active" : "pe-none"}`}
            onclickHandler={() => submit()}
            isLoading={isLoading}
            size="sm"
          />

          <ToastContainer
            position="top-right"
            autoClose={4000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
        </div>
      </div>
      <div className="footer-bottom">
        <span>©SwiftyGlobal {currentYear}. All rights reserved</span>
        <div className="d-flex">
          <a href="https://twitter.com/SwiftyGlobal" target="_blank">
            <TwitterIcon fill={isMobile ? "#98A2B3" : "#464655"} />
          </a>
          <a
            target="_blank"
            href="https://www.linkedin.com/company/swifty-global"
          >
            <LinkedInIcon fill={isMobile ? "#98A2B3" : "#464655"} />
          </a>
          {/* <a href="https://www.instagram.com/swiftysports_sb/" target="_blank">
            <InstagramIcon fill={isMobile ? "#98A2B3" : "#464655"} />
          </a> */}
        </div>
      </div>
    </div>
  );
};

export default FooterV2;
