import React, { useEffect, useState } from "react";
import { PlayIcon } from "../utils/icons";
import axios from "axios";
import SpinnerComponent from "../components/Spinner/SpinnerComponent";
import VideoBackground from "../components/VideoBackground/VideoBackground";
import IsMobile from "../utils/isMobile";

import "../components/Styles/Media.css";

const Media = () => {
  const isMobile = IsMobile(575);

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [bannerData, setBannerData] = useState([]);
  const [playVideo, setPlayVideo] = useState("");

  const getMedia = () => {
    setIsLoading(true);

    const url =
      "https://18z0qwmdu8.execute-api.eu-west-1.amazonaws.com/main/v1/data";
    const payload = {
      collection: "media",
      sort: { date: -1 },
    };

    axios
      .post(url, payload)
      .then((res) => {
        const { data } = res;
        const featured = ["1", "2", "3"];
        const documents = data.documents;

        const sortedDocuments = documents
          .filter((row) => featured.includes(row?.featured))
          .sort(
            (a, b) =>
              featured.indexOf(a.featured) - featured.indexOf(b.featured)
          );

        setBannerData(sortedDocuments);
        setData(documents.filter((row) => !featured.includes(row.featured)));
      })
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getMedia();
  }, []);

  return (
    <>
      <div className="media-title-container">
        <div className={`container media-title`}>
          <h1>
            A hub for all the latest videos and technological advancements from
            Swifty
          </h1>
          <p>
            Explore Swifty's cutting-edge advancements in Sports, Technology,
            and Predictions. Discover how Swifty is revolutionizing sports
            betting and interactive gaming through innovative solutions and
            immersive experiences.
          </p>
        </div>
      </div>
      <div className={`${isMobile ? "" : "container"} overflow-x-hidden`}>
        <div className="media-container">
          {isLoading ? (
            <div className="text-center">
              <SpinnerComponent />
            </div>
          ) : (
            <>
              <div className="madia-banner-container">
                {bannerData.map((row, i) => {
                  return (
                    <div key={i} className={`media-banner`}>
                      <div className="image-container">
                        <ImageComponent row={row} />

                        <div className="icon">
                          <PlayIcon onClick={() => setPlayVideo(row._id)} />
                        </div>
                      </div>
                      <div className="title">
                        <p>{`${row?.featured} /`}</p>
                        <p className="m-0">{row.title}</p>
                      </div>
                      {playVideo === row._id && (
                        <VideoBackground
                          videoSrc={row.video}
                          isVideoOpen={playVideo}
                          setIsVideoOpen={setPlayVideo}
                          page="media"
                        />
                      )}
                    </div>
                  );
                })}
              </div>
              <div className="madia-video-container">
                <h3 className="d-none d-lg-block">Media</h3>
                <div className="row">
                  {data.map((row, i) => {
                    return (
                      <div
                        key={i}
                        className={`col-12 col-lg-4 col-md-6 madia-video`}
                      >
                        <div className="image-container">
                          <ImageComponent row={row} />

                          <div className="icon">
                            <PlayIcon onClick={() => setPlayVideo(row._id)} />
                          </div>
                        </div>
                        <span>{row.title}</span>
                        {playVideo === row._id && (
                          <VideoBackground
                            videoSrc={row.video}
                            isVideoOpen={playVideo}
                            setIsVideoOpen={setPlayVideo}
                            page="media"
                          />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Media;

export const ImageComponent = ({ row }) => {
  const [imageSrc, setImageSrc] = useState(null);

  const checkImageExists = (url, callback) => {
    const img = new Image();
    img.onload = () => callback(url);
    img.onerror = () => callback("../images/swifty-placeholder.png");
    img.src = url;
  };

  useEffect(() => {
    // Check if the image exists
    checkImageExists(row?.image, (url) => {
      setImageSrc(url);
    });
  }, [row?.image]);

  return <img src={imageSrc} alt={row?.title} />;
};
