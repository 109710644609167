export const locations = [
  {
    name: "NEW YORK",
    email: "hello@swifty.global",
    phone: "+1 (917) 970-1433",
    locations: "26 Broadway, Suite 934, New York, NY 10004, USA",
    latitude: 40.7074,
    longitude: -74.012,
  },
  {
    name: "LONDON",
    email: "hello@swifty.global",
    phone: "+44 (0) 204 542 4517",
    locations:
      "71-75, Shelton Street, Covent Garden, London, WC2H 9JQ, United Kingdom",
    latitude: 51.5149,
    longitude: 0.1245,
  },
  {
    name: "DUBAI",
    email: "hello@swifty.global",
    phone: "+971 (0) 4 512 4070",
    locations: "Meydan, Nad Al Sheba Dubai, UAE",
    latitude: 25.078,
    longitude: 55.1413,
  },
];
